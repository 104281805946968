// @flow
import React, {Component} from "react";
import {Avatar, Input, List, Select, Spin, Row, Col} from "antd";
import * as _ from "lodash";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import productList from "./../../graphql/queries/productList.graphql"

type Props = {
    query: string,
    getProductList: {
        loading: boolean,
        productList: any,
        refetch: ?Function,
    },
    componentTypeIndex: null | string
}

class ProductSearchResultList extends Component<Props, State> {

    render() {
        const {getProductList, onSelect, t} = this.props;

        if (getProductList.loading) {
            return <Row justify={"center"}>
                <Spin/>
            </Row>;
        }

        const data = getProductList.productList.products || [];

        return <List
                style={{overflow: 'auto', maxHeight: '60vh'}}
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => (
                    <List.Item onClick={() => onSelect(Number.parseInt(item.id, 10))}>
                        <Row style={{width: '100%'}}>
                            <Col style={{alignItems: 'center'}}>
                                <Avatar shape={'square'}
                                        style={{verticalAlign: 'middle'}}
                                        icon={"code-sandbox"}
                                        size={70}
                                        src={_.get(item, 'image.thumbnailLink')}/>
                            </Col>
                            <Col style={{paddingLeft: 10}}>
                                <span style={{fontSize: 18}}>{item.name}</span><br/>
                                <span style={{fontSize: 12}}>{t('Catalog No')}: {item.catalogNo}</span><br/>
                                <span style={{fontSize: 12}}>{t('Manufacturer')}: {item.manufacturer.name}</span>
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />;
    }

}

export default compose(
    graphql(productList, {
        name: 'getProductList',
        options: props => ({
            skip: props.query === null,
            variables: {
                params: {
                    start: 0,
                    limit: 10,
                    search: {
                        query: props.query,
                        fields: ['name', 'catalogNo']
                    },
                    filter: {componentTypeIndex: {value: props.componentTypeIndex, operator: 'in'}}
                }
            }
        }),
    }),
    withTranslation(),
)(ProductSearchResultList);