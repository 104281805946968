// @flow
import React, {Component} from "react"
import ContentHeader from "../../components/Typo/ContentHeader";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button} from "@dreebit/react-components";
import NavigationContainer from "../../components/NavigationContainer";
import withResources from "../../hoc/withResources";
import ProcessList from "../../components/ProcessList";

type Props = {
    t: Function,
}

class ProcessListRoute extends Component<Props> {


    render() {

        const {t} = this.props;

        return <NavigationContainer navigationBar={<ContentHeader title={t('Processes')} tools={<Link to={"/admin/processes/new"}>
            <Button>{t('Add')}</Button>
        </Link>}/>}>
            <ProcessList/>
        </NavigationContainer>
    }

}

export default withResources(["processEditor"])(withTranslation()(ProcessListRoute));
