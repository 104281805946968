import Keycloak from "keycloak-js";
import _ from "lodash";

/**
 *
 * @type {Keycloak.KeycloakInstance|null}
 * @private
 *
 clientId: "vsm-public"
 realm: "dev"
 url: "https://mgmt-vacuum.westeurope.cloudapp.azure.com/auth/"
 // "{\"url\":\"https://mgmt-vacuum.westeurope.cloudapp.azure.com/auth/\",\"realm\":\"dev\",\"clientId\":\"vsm-public\"}"
 //new Keycloak({url: "https://mgmt-vacuum.westeurope.cloudapp.azure.com/auth/", realm: "dev", clientId: "vsm-public"});
 */

const _keycloak = _.get(window, '__BE__.keycloak') ? new Keycloak({
    ...window.__BE__.keycloak
}): process?.env?.NODE_ENV === 'development' ? new Keycloak({url: "https://dev.sso.pfeiffer-vacuum.com/auth/", realm: "dev", clientId: "vsm-public"}) : null;

export default class KeycloakService {

    static getInstance = () => {
        return _keycloak
    }

    static init = () => {
        return _keycloak.init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        })
    }

    static logout = () => {
        if (!_keycloak) return Promise.resolve();

        try {
            return _keycloak.logout({

            });
        }catch (e){
            return Promise.resolve()
        }


    }

};
