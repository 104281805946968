// @flow
import React, {Component} from 'react';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import type {Query} from "../../types";
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";
import {Link, withRouter} from "react-router-dom";
import * as _ from "lodash";
import {getPathText} from "../ComponentPathBreadcrumb";
import ComponentImage from "../ComponentImage";
import * as moment from "moment";
import FillQueryTable from "../QueryTable/fill";
import {Tooltip} from "antd";
import ServiceTicketStateBadge from "../ServiceTicketStateBadge";

type Props = {
    query: Query,
    itemsKeyPath?: string,
    totalKeyPath?: string,
    showPath?: boolean,
    showColumns?: string[],
};


class ServiceTicketsTable extends Component<Props, State> {

    static defaultProps = {
        itemsKeyPath: "serviceTicketList.serviceTickets",
        totalKeyPath: "serviceTicketList.total",
        showPath: true,
    };

    render() {
        const {t, history, showColumns} = this.props;

        return <FillQueryTable
            className={"link-table"}
            size={'middle'}
            onRow={(record) => {
                return {
                    onClick: () => {
                        history.push(`/service/${record.id}`)
                    },
                };
            }}
            columns={[
                {
                    dataIndex: 'image',
                    key: 'image',
                    width: 60,
                    render: (text, record) => {
                        return <ComponentImage size={50} link={_.get(record, 'component.image.thumbnailLink')}/>
                    },
                },
                {
                    title: t('Key'),
                    dataIndex: 'key',
                    key: 'key',
                    sorter: true,
                    width: 140,
                    render: (text, record) => {
                        return <div>
                            <div>
                            </div>
                                <ServiceTicketTypeIcon index={_.get(record,'serviceTicketType.index')}/> {_.get(record,'key')}
                            <div>
                                <ServiceTicketStateBadge style={{fontSize: 10}} state={_.get(record,'serviceTicketState')}/>
                            </div>

                        </div>
                    },
                },
                {
                    title: t('Component'),
                    dataIndex: 'component',
                    key: 'component',
                    width: "25%",
                    render: (text, record) => {
                        const path = _.get(record, 'component.path', []);
                        const pathText = getPathText(path);
                        const label = <Link
                            className={"text-link"}
                            to={`/infrastructure/${_.get(record, 'component.id')}`}>{_.get(record, 'component.name')}</Link>

                        if (path.length > 1) {
                            return <Tooltip title={pathText}>
                                <div>
                                    <div>
                                        {label}
                                    </div>
                                    <div>
                                        {t("SN")}: {_.get(record, 'component.serialNo')}
                                    </div>
                                </div>
                            </Tooltip>
                        }

                        return label;

                    },
                },
                {
                    title: t('Client'),
                    dataIndex: 'client.name',
                    key: 'client',
                    width: "25%",
                },
                {
                    title: t('Last Action'),
                    dataIndex: 'lastServiceAction.serviceActionType.name',
                    key: 'lastServiceAction',
                    width: "35%",
                    render: (text, record) => {
                        return <div>
                            <div>
                                {_.get(record, 'lastServiceAction.serviceActionType.code')}: {_.get(record, 'lastServiceAction.serviceActionType.name')}
                            </div>
                            <div style={{color: "rgba(0, 0, 0, 0.45)"}}>
                                <span>{moment(_.get(record, 'lastServiceAction.insert')).format('LLL')}</span>
                            </div>
                        </div>

                    }
                }

            ].filter((item) => showColumns ? showColumns.indexOf(item.key) > -1 : true)}
            query={this.props.query}
            itemsKeyPath={this.props.itemsKeyPath}
            totalKeyPath={this.props.totalKeyPath}
        />
    }
}

export default compose(
    withRouter,
    withTranslation(),
)(ServiceTicketsTable);
