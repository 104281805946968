// @flow
import React, {Component} from "react"
import {Divider, Row} from "antd";
import {withTranslation} from "react-i18next";
import ProductSearchBar from "../ProductSearchBar";
import ProductCreateForm from "./ProductCreateForm";
import {LeftOutlined} from "@ant-design/icons";

type Props = {
    onSelectProduct?: Function,
    onProductExposed?: Function,
}

type State = {
    createProductForm: boolean,
}

class ComponentCreateProduct extends Component<Props, State> {


    state = {
        createProductForm: false,
        createProductType: {}
    };


    render() {

        const {t, onSelectProduct, onProductExposed} = this.props;

        return <>
            {this.state.createProductForm
                ? <div className={'pr-15'}>
                    <Row className={'pb-15'}>
                        <LeftOutlined style={{fontSize:20}} onClick={() => this.setState({createProductForm: false})}/>
                        <h3 className={'ph-5'}>{t('Create new component')}</h3>
                    </Row>
                    <ProductCreateForm onProductExposed={onProductExposed} defaultValue={this.state.createProductType}/>
                </div> :
                <ProductSearchBar onSelect={onSelectProduct} onExtraLink={(val) => this.setState({createProductForm: true, createProductType: val})}/>
            }
        </>
    }

}

export default withTranslation()(ComponentCreateProduct);
