import React from 'react';
import {Icon} from "@dreebit/react-components";
import './index.less'

interface Props {
    title: string,
    icon: string | React.ReactNode,
    link?: string,
    disabled?: boolean,
    style?: React.CSSProperties
}

const MicroserviceAppLink = ({title, link, icon, disabled, style}: Props) => {
    const content = <div className={"flex-row flex-align-items-center"} style={style}>
        <div className={`app-link-icon ${disabled ? "disabled" : ""}`}>
            {
                typeof icon === "string" ? <Icon type={icon}/> : icon
            }
        </div>
        <span className={`app-link-title ${disabled ? "disabled" : ""}`}>
                {title}
        </span>
    </div>

    if (link && !disabled) {
        return <a href={link} target="_blank" rel="noopener noreferrer" className={"microservice-app-link"}>
            {content}
        </a>
    }

    return content;
}

export default MicroserviceAppLink;
