// @flow
import React, {Component} from "react"
import {Col, ConfigProvider, Row, Segmented} from "antd";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import ComponentCreateProduct from "./ComponentCreateProduct";
import Panel from "../Panel";
import {compose} from "recompose";
import _ from "lodash";
import ComponentCreateComponentTypes from "./ComponentCreateComponentTypes";
import InfrastructureCreateTokenRoute from "../../routes/InfrastructureCreateComponent/InfrastructureCreateToken";

type Props = {
    t: Function,
    onSelectProduct: ?Function,
    onCreateProduct: ?Function,
    onSelectComponentType: ?Function,
    match: {
        params: {
            id: string,
            index: string,
        }
    },
    history: {
        push: Function
    }
}

class ComponentCreate extends Component<Props> {
    constructor(props) {
        super(props);
        this.routes = ['component', 'infrastructureUnit', 'token'];
        this.state = {
            selectedIndex: this.getInitialIndex()
        };
    }

    getInitialIndex = () => {
        const {match} = this.props;
        const routeIndex = this.routes.indexOf(match.params.index);
        return routeIndex !== -1 ? routeIndex : 0;
    }

    onChange = (value) => {
        const {history, match} = this.props;
        const route = `/infrastructure/create/${match.params.id}/${this.routes[value]}`;
        history.push(route);
        this.setState({selectedIndex: value});
    }


    render() {

        const {t, onCreateProduct, onSelectProduct, onSelectComponentType} = this.props;
        const values = [{label: t('Component'), value: 0},
            {label: t('Infrastructure Unit'), value: 1},
            {label: t('Token'), value: 2}];

        const parentId = _.get(this.props, 'match.params.id');

        return <Col lg={{span: 16, offset: 4}}
                    xl={{span: 14, offset: 5}}
                    xxl={{span: 12, offset: 6}}>
            <div tour-id="createComponent__newDevice">
                <Panel>
                    <Row justify={'center'}>
                        <Segmented
                            size={"large"}
                            onChange={this.onChange}
                            defaultValue={this.state.selectedIndex}
                            options={values}/>
                    </Row>
                    <Panel>
                        {this.state.selectedIndex === 0 &&
                            <ComponentCreateProduct onProductExposed={onCreateProduct}
                                                    onSelectProduct={onSelectProduct}/>
                        }
                        {this.state.selectedIndex === 1 && <ComponentCreateComponentTypes
                            parentId={parentId}
                            onSelectComponentType={onSelectComponentType}/>}
                        {this.state.selectedIndex === 2 && <InfrastructureCreateTokenRoute parentId={parentId}/>}
                    </Panel>
                </Panel>
            </div>
        </Col>;
    }
}

export default compose(
    withTranslation(),
    withRouter
)(ComponentCreate);
