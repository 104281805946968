import React, { useEffect, useState } from 'react';
import Tour from "reactour";
import {TourStep} from "../TourStep"
import _ from "lodash";
import {tourConfig} from "./tourConfig";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import { graphql } from '@apollo/client/react/hoc';
import withTheme from "../../hoc/withTheme";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import TourProgressQuery from "../../graphql/queries/tourProgress.graphql";
import UpdateUserTourProgressMutation from "../../graphql/mutations/updateUserTourProgress.graphql";
import {withTranslation} from "react-i18next";
import {getRemainingTourSteps} from "../../utils/getRemainingTourSteps";
import {formatTourInput} from "../../utils/getAllTourSteps";
import "./index.css";

const WelcomeTour = ({ tourProgressQuery, updateUserTourProgressMutation, location: { pathname }, t, themeConfig, history, profile }) => {

  const tourProgress = _.get(tourProgressQuery, 'profile.tourProgress');
  const accentColor = themeConfig["@primary-color"]
  const filteredTourConfig = getRemainingTourSteps(tourConfig, tourProgress)

  const steps = _.flatten([
    _.get(filteredTourConfig, 'global', []),
    ...(
        pathname === "/dashboard" ? _.get(filteredTourConfig, 'dashboard', [])
      : pathname === "/infrastructure/dashboard" ? _.get(filteredTourConfig, 'infrastructure', [])
      : pathname.match(/\/infrastructure\/create\/(.*)\/component/i) ? _.get(filteredTourConfig, 'createComponent', [])
      : pathname.match(/\/infrastructure\/(.*)\/details/i) ? _.get(filteredTourConfig, 'componentDashboard', [])
      : pathname === "/service/search" ? _.get(filteredTourConfig, 'serviceSearch', [])
      : pathname === "/calendar" ? _.get(filteredTourConfig, 'calendar', [])
      : []
    )
  ])

  const stepsTranslated = steps.map(o => typeof o.content === 'string' ? Object.assign({}, o, {
    content: t(o.content)
  }) : Object.assign({}, o, {
    content: o.content
  }));

  const [isTourOpen, setTourVisibility] = useState(false);
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
      setTimeout(() => {
          setTourVisibility(steps.length > 0)
      }, 400)
  }, [pathname])

  return (
    <Tour
      steps={stepsTranslated}
      isOpen={isTourOpen && steps.length && _.get(tourProgressQuery, 'profile.acceptBusinessTerms', false)}
      onRequestClose={() => setTourVisibility(false)}
      onBeforeClose={() => {
        const doneSteps = formatTourInput(steps.slice(0, currentStep + 1))

        updateUserTourProgressMutation({
          variables: {
            input: doneSteps
          }
        })
      }}
      update={pathname}
      accentColor={accentColor}
      disableInteraction={true}
      startAt={0}
      getCurrentStep={curr => setCurrentStep(curr)}
      maskClassName="mask"
      CustomHelper={TourStep}
    />
  );
}

export default compose(
  withRouter,
  withTheme(),
  withTranslation(),
  graphql(TourProgressQuery, {
    name: 'tourProgressQuery'
  }),
  waitWhileLoading('tourProgressQuery'),
  graphql(UpdateUserTourProgressMutation, {
    name: 'updateUserTourProgressMutation',
    options: () => ({
      refetchQueries: [{
        query: TourProgressQuery
      }]
    })
  }),
)(WelcomeTour);
