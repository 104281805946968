// @flow
import React, {Component} from "react"
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import queryString from "query-string";
import ComponentCreate from "../../components/ComponentCreate/ComponentCreate";

type Props = {
    match: {
        params: {
            id: string
        }
    },
    history: {
        push: Function
    }
}

class InfrastructureCreateComponentRoute extends Component<Props> {

    redirectUrl = null;

    constructor(props) {
        super(props);

        this.redirectUrl = `/infrastructure/create/${this.props.match.params.id}/details`;
    }

    onSelectComponentType = (componentTypeIndex) => {
        this.redirect({componentTypeIndex});
    };

    onCreateProduct = (product) => {
        this.redirect({
            componentTypeIndex: (() => {
                switch(product.assetType) {
                    case 'product':
                        return 'componentProduct';
                    case 'tool':
                        return 'tool';
                    case 'chamber':
                        return 'chamber';
                    default:
                        return 'componentProduct';
                }
            })(),
            product
        });
    };

    onSelectProduct = (productId) => {
        this.redirect({
            productId
        });
    };

    redirect(params) {
        const {history} = this.props;
        params.product = queryString.stringify(params.product);

        const stringified = queryString.stringify(params);
        history.push(`${this.redirectUrl}?${stringified}`)
    }

    render() {
        return <ComponentCreate
            parentId={_.get(this.props, 'match.params.id')}
            onCreateProduct={this.onCreateProduct}
            onSelectProduct={this.onSelectProduct}
            onSelectComponentType={this.onSelectComponentType}
        />;
    }

}

export default  withRouter(withTranslation()(InfrastructureCreateComponentRoute));
