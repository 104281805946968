// @flow
import React, {Component} from 'react';
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import styled from "styled-components";
import ContentHeader from "../../components/Typo/ContentHeader";

import Sizes from "../../themes/default/sizes";
import Panel from "../../components/Panel";
import withResources from "../../hoc/withResources";
import ProcessCreate from "../../components/ProcessCreate";

const ContentWrapper = styled.div`
   padding: ${Sizes.grid}px;
`;

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};


class ProcessCreateRoute extends Component<Props, State> {

    _handleOnCreate = (res) => {
        const {history} = this.props;
        const id = _.get(res, 'data.createProcess.id');
        if (id) {
            history.push(`/admin/processes/${id}`);
        } else {
            history.push(`/admin/processes`);
        }
    };

    render() {
        const {t} = this.props;

        return <div>
            <ContentHeader title={t('Process')}/>
            <ContentWrapper>
                <Panel>
                    <ProcessCreate onCreate={this._handleOnCreate}/>
                </Panel>
            </ContentWrapper>
        </div>;
    }
}

export default withResources(["processEditor"])(withTranslation()(ProcessCreateRoute)) ;
