import * as React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
    color: string
}

const SvgMSInstalledBaseIcon = ({color, ...props}: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 14"
        fill="none"
        {...props}
    >
        <g fill={color} clipPath="url(#a)">
            <path d="M19.933 11.218V6.2a.332.332 0 0 0-.142-.25l-3.576-2.082a.312.312 0 0 0-.284 0l-3.576 2.086a.322.322 0 0 0-.142.249v5.018a.318.318 0 0 0 .142.248l3.576 2.085a.302.302 0 0 0 .284 0l3.576-2.086a.321.321 0 0 0 .142-.25Z" />
            <path d="M13.756 2.455 10.182.367a.316.316 0 0 0-.285 0L6.32 2.455a.318.318 0 0 0-.142.247V4.46l1.692.992a.894.894 0 0 1 .433.752v2.925l1.594.93a.31.31 0 0 0 .285 0l1.449-.846V6.2a.898.898 0 0 1 .431-.753l1.837-1.071V2.702a.316.316 0 0 0-.143-.247Z" />
            <path d="M6.18 2.702a.319.319 0 0 1 .142-.247L9.9.367a.316.316 0 0 1 .285 0l3.575 2.088a.315.315 0 0 1 .143.247v.093a.32.32 0 0 1-.143.249l-3.575 2.085a.316.316 0 0 1-.285 0L6.322 3.044a.321.321 0 0 1-.142-.25v-.092ZM7.72 11.218V6.2a.32.32 0 0 0-.142-.25L4.001 3.869a.288.288 0 0 0-.142-.034.284.284 0 0 0-.141.034L.142 5.954A.322.322 0 0 0 0 6.203v5.018a.319.319 0 0 0 .142.248l3.576 2.085a.286.286 0 0 0 .141.036c.05 0 .099-.012.142-.036l3.577-2.086a.315.315 0 0 0 .142-.25Z" />
            <path d="M0 6.2a.322.322 0 0 1 .142-.25l3.576-2.082a.314.314 0 0 1 .283 0l3.577 2.086a.32.32 0 0 1 .142.249v.088a.315.315 0 0 1-.142.248L4.001 8.626a.314.314 0 0 1-.283 0L.142 6.54A.318.318 0 0 1 0 6.292V6.2ZM12.213 6.2a.322.322 0 0 1 .142-.25l3.576-2.082a.314.314 0 0 1 .284 0l3.576 2.086a.33.33 0 0 1 .142.249v.088a.322.322 0 0 1-.142.248l-3.576 2.087a.314.314 0 0 1-.284 0L12.355 6.54a.318.318 0 0 1-.142-.248V6.2Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill={color} d="M0 .333h20v13.333H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default SvgMSInstalledBaseIcon
