// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import _ from 'lodash'
import {Col, message, Popconfirm, Row} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {withProps} from "recompose";
import ContentHeader from "../Typo/ContentHeader";
import processFamilyDetails from "./../../graphql/queries/processFamilyDetails.graphql";
import deleteProcessFamily from "./../../graphql/mutations/deleteProcessFamily.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {ProcessFamilyForm} from "../ProcessFamilyForm";
import Panel from "../Panel";
import ContentWrapper from "../ContentWrapper";
import Sizes from "../../themes/default/sizes";
import processFamilyList from "../../graphql/queries/processFamilyList.graphql";
import {withRouter} from "react-router-dom";
import withProfile from "../../hoc/withProfile";

type Props = {
    id: string,
    processFamilyDetailsQuery: any,
    deleteProcessFamilyMutation: Function,
    history: {
        push: Function
    }
}

class ProcessFamilyDashboard extends Component<Props> {

    handleDelete = () => {
        const {deleteProcessFamilyMutation, id, history} = this.props;

        deleteProcessFamilyMutation({variables: {id: id}}).then((res) => {
            message.success(res.data.deleteProcessFamily.message);
            history.push(`/admin/processFamilies`)
        });
    };


    render() {

        const {process, id, t} = this.props;
        const deleteButton = <Popconfirm
            placement="bottomRight" title={t("Are you sure?")}
            onConfirm={this.handleDelete}
            okText={t("Yes")}
            cancelText={t("No")}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
        >
            <Button type="danger" icon="delete">{t('Delete')}</Button>
        </Popconfirm>;

        return <div>
            <ContentHeader title={_.get(process, 'name')} tools={deleteButton} />
            <ContentWrapper>
                <Row gutter={Sizes.grid * 2}>
                    <Col xl={24}>
                        <Panel
                            title={t('Details')}
                        >
                            <ProcessFamilyForm id={id}/>
                        </Panel>

                    </Col>
                </Row>
            </ContentWrapper>
        </div>;
    }

}

export default compose(
    withTranslation(),
    withProfile(),
    withRouter,
    graphql(processFamilyDetails, {
        name: 'processFamilyDetailsQuery',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    waitWhileLoading('processFamilyDetailsQuery', 'processFamily'),
    graphql(deleteProcessFamily, {
        name: 'deleteProcessFamilyMutation',
        options: props => ({
            refetchQueries: [{
                query: processFamilyList,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        })
    }),
    withProps((props) => ({
        process: _.get(props, 'processFamilyDetailsQuery.processFamily'),
    }))
)(ProcessFamilyDashboard);
