import _ from "lodash";
import React, {Component} from 'react'
import IconSplitPanelServiceTicket from "../IconSplitPanelServiceTicket"
import IconSplitPanelServicePlan from "../IconSplitPanelServicePlan"
import IconSplitPanelFiles from "../IconSplitPanelFiles"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import ComponentQuery from "../../graphql/queries/component-componentType.graphql";
import FaultListQuery from "../../graphql-federated/queries/faultList.graphql";
import FindAssetMetricAttributeQuery from "../../graphql-federated/queries/findAssetMetricAttribute.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import IconSplitPanelTCO from "../IconSplitPanelTCO";
import withProfile from "../../hoc/withProfile";
import {checkResource} from "../../hoc/withResources";
import ComponentHealthIndexSplitPanel from "../ComponentHealthIndexSplitPanel";
import ComponentAlertsSplitPanel from "../ComponentAlertsSplitPanel";


type Props = {
    componentId: string,
    showAlerts: boolean,
    assetId?: string,
    isSemiconComponentType?: boolean
}

class ComponentWidgets extends Component<Props> {

    render() {
        const {componentId, componentQuery, faultListQuery, resources, findAssetMetricAttributeQuery, showAlerts, assetId, isSemiconComponentType} = this.props;

        const product = _.get(componentQuery, 'component.componentType.product', false);
        const clientId = _.get(componentQuery, 'component.client.id');
        // const activeAlerts = _.get(componentQuery, 'component.activeAlerts');
        const faults = _.get(faultListQuery, 'faultList.faults', [])
        //const activeAlerts = [
        //     {
        //         id: 1,
        //         metadata: '',
        //         created: 1719908268,
        //         updated: 1719908268,
        //         alertKey: 'pvseriallink:N2_Fault:2',
        //         groupKey: 'pvseriallink:N2_Fault',
        //         level: 'error',
        //         assetId: 1, // internal asset id
        //         globalAssetId: 'urn:product:pfeiffer-vacuum.com:hipace80:pmp04001',  // urn:asset:pfeiffer-vacuum.com:hipace80:pmp04001:32424242
        //         active: true,
        //         title: 'N2 Fault',
        //         description: 'N2 flow is too low',
        //         possibleCauses: 'N2 flow sensor is broken',
        //         recommendedActions: 'Replace N2 flow sensor'
        //     },
        //     {
        //         id: 2,
        //         metadata: '',
        //         created: 1719908268,
        //         updated: 1719908268,
        //         alertKey: 'pvseriallink:N2_Fault:2',
        //         groupKey: 'pvseriallink:N2_Fault',
        //         level: 'info',
        //         assetId: 1, // internal asset id
        //         globalAssetId: 'urn:product:pfeiffer-vacuum.com:hipace80:pmp04001',  // urn:asset:pfeiffer-vacuum.com:hipace80:pmp04001:32424242
        //         active: true,
        //         title: 'N2 Fault',
        //         description: 'N2 flow is too low',
        //         possibleCauses: 'N2 flow sensor is broken',
        //         recommendedActions: 'Replace N2 flow sensor'
        //     },
        //     {
        //         id: 3,
        //         metadata: '',
        //         created: 1719908268,
        //         updated: 1719908268,
        //         alertKey: 'pvseriallink:N2_Fault:2',
        //         groupKey: 'pvseriallink:N2_Fault',
        //         level: 'warning',
        //         assetId: 1, // internal asset id
        //         globalAssetId: 'urn:product:pfeiffer-vacuum.com:hipace80:pmp04001',  // urn:asset:pfeiffer-vacuum.com:hipace80:pmp04001:32424242
        //         active: true,
        //         title: 'N2 Fault',
        //         description: 'N2 flow is too low',
        //         possibleCauses: 'N2 flow sensor is broken',
        //         recommendedActions: 'Replace N2 flow sensor'
        //     },
        //     {
        //         id: 4,
        //         metadata: '',
        //         created: 1719908268,
        //         updated: 1719908268,
        //         alertKey: 'pvseriallink:N2_Fault:2',
        //         groupKey: 'pvseriallink:N2_Fault',
        //         level: 'error',
        //         assetId: 1, // internal asset id
        //         globalAssetId: 'urn:product:pfeiffer-vacuum.com:hipace80:pmp04001',  // urn:asset:pfeiffer-vacuum.com:hipace80:pmp04001:32424242
        //         active: true,
        //         title: 'N2 Fault',
        //         description: 'N2 flow is too low',
        //         possibleCauses: 'N2 flow sensor is broken',
        //         recommendedActions: 'Replace N2 flow sensor'
        //     }
        // ]

        const hasAlerts = _.get(faultListQuery, 'faultList.total') > 0;

        // const healthIndex = 1.35
        const healthIndex = _.get(findAssetMetricAttributeQuery, 'findAssetMetricAttribute.lastValue.value.healthIndex')
        const healthLevel = _.get(findAssetMetricAttributeQuery, 'findAssetMetricAttribute.lastValue.value.healthLevel')
        const healthIndexValueList = _.get(findAssetMetricAttributeQuery, 'findAssetMetricAttribute.valueList.values')

        const hasTCO = checkResource("tcoAdministration", resources)

        return <div className="component-widgets-wrapper">
            {
                showAlerts && hasAlerts > 0 ?
                    <ComponentAlertsSplitPanel
                        componentId={componentId}
                        faults={faults}
                    />
                    : undefined
            }
            {
                healthIndex && typeof healthIndex === "number" ?
                    <ComponentHealthIndexSplitPanel
                        healthIndex={healthIndex}
                        healthLevel={healthLevel}
                     healthData={healthIndexValueList}/>
                    : undefined
            }

            {product && !isSemiconComponentType ? <IconSplitPanelServiceTicket key={'ticket'} componentId={componentId}/> : undefined}
            {product && !isSemiconComponentType ? <IconSplitPanelServicePlan key={'plan'} componentId={componentId}/> : undefined}
            {hasTCO && !isSemiconComponentType ? <IconSplitPanelTCO componentId={componentId}/> : undefined}

            <IconSplitPanelFiles componentId={componentId}/>
        </div>

        // <IconSplitPanelTCO componentId={componentId}/>
    }
}

export default compose(
    withTranslation(),
    withProfile(),
    graphql(ComponentQuery, {
        name: 'componentQuery',
        options: props => ({
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('componentQuery', 'component'),
    graphql(FaultListQuery, {
        name: 'faultListQuery',
        skip: props => !props.assetId,
        options: props => ({
            variables: {
                filter: {
                    globalAssetId: props.assetId
                    // globalAssetId: "urn:asset:pfeiffer-vacuum.com:hipace80:pmp04260:32424242"
                }
            },
            context: {
                clientName: 'federatedGraphEndpoint'
            },
        }),
    }),
    waitWhileLoading('faultListQuery', 'faultList', {
        showLoader: false,
        optional: (props: Props) => !props.assetId
    }),
    graphql(FindAssetMetricAttributeQuery, {
        name: 'findAssetMetricAttributeQuery',
        skip: props => !props.assetId,
        options: props => ({
            variables: {
                aspectId: "urn:samm:io.vacuumx.telemetry.health-indication:1.0.0#HealthIndication",
                globalAssetId: props.assetId
            },
            context: {
                clientName: 'federatedGraphEndpoint'
            },
            pollInterval: 5000
        }),
    }),
    waitWhileLoading('findAssetMetricAttributeQuery', 'findAssetMetricAttribute', {
        showLoader: false,
        optional: (props: Props) => !props.assetId
    }),
)(ComponentWidgets)
