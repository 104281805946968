import _ from "lodash";
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import * as axios from "axios";
import AddTranslationMutation from "../graphql/mutations/addTranslation.graphql";
import {getJwtLanguage} from "../utils/parseJWT";
import keycloak from "../keycloak";


export const lngDesinationMap: {[key: string]: string} = {
    'de': 'de-DE',
    'en': 'en-EN',
    'de-DE': 'de-DE',
    'en-EN': 'en-EN',
    'en_EN': 'en-EN',
    'de_DE': 'de-DE'
}

const backendDestinationMap: {[key: string]: string} = {
    'de': 'de_DE',
    'en': 'en_EN',
    'de-DE': 'de_DE',
    'en-EN': 'en_EN',
    'en_EN': 'en_EN',
    'de_DE': 'de_DE'
};

const jwtLanguage = getJwtLanguage(keycloak.token)
const lng = lngDesinationMap[jwtLanguage]

export default () => {

    i18n
        .use(Backend)
        .use(initReactI18next)
        .init({
            lng: lng,
            fallbackLng: 'en-EN',
            ns: ['translations'],
            defaultNS: 'translations',
            load: 'currentOnly',
            keySeparator: false, // we use content as keys
            debug: true,
            initImmediate: true,
            saveMissing: true,
            nonExplicitWhitelist: true,
            interpolation: {
                escapeValue: false, // not needed for react!!
                formatSeparator: ','
            },
            backend: {
                loadPath: (lngs, namespaces) => {
                    const lang = backendDestinationMap[lngs] || 'en_EN';
                    return `https://translator.vsm-cloud.com/translations/vsm/${lang}/web`
                },
                addPath: '/graphql',
                ajax: function (url, options, callback, data) {

                    const read = !data;
                    if (read) {
                        return axios.get(url, {}).then((res) => {
                            callback(JSON.stringify(res.data), res);
                        }).catch((err) => {
                            console.error(err);
                            callback(null, {});
                        })
                        //return ajax(url, options, callback, data)
                    } else if (window.__DEV__ || _.get(window, '__WRITE_TRANSLATIONS__') === true) {

                        const key = _.first(Object.keys(data));
                        if (!key) return;
                        const mutationText = _.get(AddTranslationMutation, 'loc.source.body');

                        console.log('Send key', data, i18n.language);
                        const bearer = localStorage.getItem('translatorBearer');
                        let promise = null;
                        if (!bearer || bearer === "null" || bearer === "undefined" || _.isEmpty(bearer)) {
                            console.log('Translator Login:', process.env.REACT_APP_TRANSLATOR_EMAIL);
                            promise = axios.post('/i18n/auth/token', {
                                grant_type: 'password',
                                client_id: 'web',
                                username: process.env.REACT_APP_TRANSLATOR_EMAIL,
                                password: process.env.REACT_APP_TRANSLATOR_PASSWORD,
                            }).then((res) => {
                                const token = _.get(res,'data.access_token');
                                console.log('Translator token:',res);
                                localStorage.setItem('translatorBearer', token);
                                return token;
                            }).catch((err) => console.error(err))
                        }else {
                            console.log("Translator saved token", bearer)
                            promise = new Promise((resolve)=>{
                                resolve(bearer);
                            })
                        }

                        return promise.then((token)=>{
                            return axios.post('/i18n/graphql', {
                                "operationName": "createTranslation",
                                "variables": {"key": key, "tag": "web"},
                                "query": mutationText
                            }, {
                                headers: {
                                    "Authorization": `Bearer ${token}`,
                                },
                            })
                        }).then((res) => {
                            callback(null, res)
                        }).catch((err) => {
                            localStorage.setItem('translatorBearer', undefined);
                            callback(null, {})
                        })
                    }
                },
            },
        }).then(() => {
        const momentLocal = _.first(i18n.language ? i18n.language.split("-") : []);
        moment.locale(momentLocal);
    });

    return i18n;
};
