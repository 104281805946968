const menu = [
    {
        id: 'dashboard',
        name: 'Dashboard',
        route: '/dashboard',
        tourId: 'global__navigation__dashboard'
    },
    {
        id: 'infrastructure',
        name: 'Infrastructure',
        route: '/infrastructure',
        tourId: 'global__navigation__infrastructure',
        // items: [
        //     {
        //         id: 'infrastructure-dashboard',
        //         name: 'Overview',
        //         route: '/infrastructure',
        //     },
        //     {
        //         id: 'stock-dashboard',
        //         name: 'Stock management',
        //         route: '/infrastructure/stock/dashboard',
        //         resources: ['administrateStocks'],
        //     },
        //     {
        //         id: 'infrastructure-search',
        //         name: 'Search',
        //         route: '/infrastructure/search'
        //     }
        // ]
    },
    {
        id: 'service',
        name: 'Service',
        route: '/service',
        tourId: 'global__navigation__service',
        // items: [
        //     {
        //         id: 'service-tickets',
        //         name: 'Service Tickets',
        //         route: '/service'
        //     },
        //     {
        //         id: 'service-budget',
        //         name: 'Servicebudget',
        //         route: '/service/budget',
        //         resources: ['showServiceBudget']
        //     },
            // deprecated: Funktionen für Service Vertrags Partner werden testweise nicht mehr angezeigt
            // ggf. später wieder einfügen oder komplett entfernen
            //
            // {
            //     id: 'contract-partners',
            //     name: 'Contract Partners',
            //     route: '/contractpartners',
            //     resources: ['administrateServiceContracts']
            // }
        // ]
    },
    {
        id: 'workflow',
        name: 'Workflow',
        route: '/workflow',
        resources: ['showWorkflow'],
    },
    {
        id: 'calendar',
        name: 'Calendar',
        route: '/calendar',
        tourId: 'global__navigation__calendar'
    },
];

export default menu;
