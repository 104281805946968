import {jwtDecode} from "jwt-decode";
import _ from 'lodash';

export const parseJwt = (token: string | undefined) => {
    if (token) {
        return jwtDecode(token);
    }
    return undefined;
}

export const getJwtLanguage = (token: string | undefined) => {
    const parsedToken = parseJwt(token);
    return _.get(parsedToken, 'language', 'en');
}

