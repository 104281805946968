// @flow
import React, {Component} from "react"
import _ from 'lodash'
import Error from "../../components/Error";
import withResources from "../../hoc/withResources";
import ProcessDashboard from "../../components/ProcessDashboard";

type Props = {
    match: any,
}

class ProcessDashboardRoute extends Component<Props> {


    render() {

        const {match} = this.props;
        const id = _.get(match, 'params.id');

        if (!id) {
            return <Error type="404"/>
        }

        return <ProcessDashboard id={id}/>;
    }

}

export default withResources(["processEditor"])(ProcessDashboardRoute)
