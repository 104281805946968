// @flow
import React from 'react';
import {useQuery} from "@apollo/client";
import BookmarkCountQuery from "../../graphql/queries/bookmarkCount.graphql";
import PortletCountQuery from "../../graphql/queries/portletCount.graphql";
import {Redirect, Route, Switch} from "react-router-dom";
import DashboardRoute from "../dashboard";
import InfrastructureCreateComponent from "../InfrastructureCreateComponent";
import Infrastructure from "../infrastructure";
import Service from "../service";
import ServiceCalendar from "../serviceCalendar";
import Admin from "../admin";
import Profile from "../profile";
import MessagesRoute from "../messages";
import Workflow from "../workflow";
import News from "../news"
import TicketRedirect from "../service/ticketRedirect";
import InfrastructureCreateTokenRoute from "../InfrastructureCreateComponent/InfrastructureCreateToken";
import _ from "lodash";


const AppRoutes = () => {
    const portletCountQuery = useQuery(PortletCountQuery)
    const bookmarkCountQuery = useQuery(BookmarkCountQuery, {
        variables: {
            params: {filter: {type: {value: 'infrastructureSearch'}}}
        }
    })

    const portletCount = _.get(portletCountQuery, 'data.portletList.total')
    const infrastructureBookmarkCount = _.get(bookmarkCountQuery, 'data.bookmarkList.total')

    return <Switch>
        <Route path="/dashboard" component={DashboardRoute}/>
        <Route from="/infrastructure/cmt/:token" component={InfrastructureCreateTokenRoute}/>
        <Route from="/infrastructure/cmt" component={InfrastructureCreateTokenRoute}/>
        <Route path="/infrastructure/create/:id" component={InfrastructureCreateComponent}/>
        <Redirect exact from="/infrastructure/create" to="/infrastructure/dashboard"/>
        <Route path="/infrastructure/:index" component={Infrastructure}/>
        <Route
            path="/infrastructure"
            component={() => <Infrastructure redirectTab={infrastructureBookmarkCount === 0 ? 'search' : null}/>}
        />
        <Route path="/service/ticket/:mongoId" component={TicketRedirect}/>
        <Route path="/service/:index" component={Service}/>
        <Route path="/service" component={Service}/>
        <Route path="/workflow" component={Workflow}/>
        <Route path="/calendar/:selectedId?/:selectedIndex?"
               component={ServiceCalendar}/>
        <Route path="/admin" component={Admin}/>
        <Route path="/news" component={News}/>
        <Route path="/messages/:id" component={MessagesRoute}/>
        <Route path="/messages" component={MessagesRoute}/>
        <Route path="/profile/:index" component={Profile}/>
        <Redirect from="/profile" to={"/profile/details"}/>
        {
            portletCount > 0 ?
                <Redirect exact from="/" to="/dashboard"/> :
                <Redirect exact from="/" to="/infrastructure"/>
        }
        <Redirect from="/" to="/dashboard"/>
    </Switch>;
}

export default AppRoutes;
