import * as React from "react"
const SvgMSAnalyticsIcon = (props) => (
    <svg
        aria-hidden="true"
        viewBox="0 0 24 24"
        fill="#fff"
        {...props}
    >
        <path
            d="M19.88 18.47c.44-.7.7-1.51.7-2.39 0-2.49-2.01-4.5-4.5-4.5s-4.5 2.01-4.5 4.5 2.01 4.5 4.49 4.5c.88 0 1.7-.26 2.39-.7L21.58 23 23 21.58zm-3.8.11a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5m-.36-8.5c-.74.02-1.45.18-2.1.45l-.55-.83-3.8 6.18-3.01-3.52-3.63 5.81L1 17l5-8 3 3.5L13 6zm2.59.5c-.64-.28-1.33-.45-2.05-.49L21.38 2 23 3.18z"/>
    </svg>
)
export default SvgMSAnalyticsIcon
