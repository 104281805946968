// @flow
import React, {Component} from "react"
import {Badge, List} from "antd";
import _ from 'lodash'
import ServiceTicketTypeIcon from "../ServiceTicketTypeIcon";
import "./listItem.less";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Toolbar from "../Toolbar";
import moment from "moment";
import MaterialCommunityIcon from "../Icons/MaterialCommunityIcon";

type Props = {
    onClick?: Function,
    item: {
        id: string,
        name: string,
        key: string,
        serviceTicketType: {
            index: string
        },
        component: {
            serialNo?: string,
            name: string,
        }
    }
}

class ServiceTicketWorkFlowListItem extends Component<Props> {


    render() {

        const {item, onClick, t} = this.props;

        if (!item) {
            return null;
        }

        const backgroundClass = this.getBackgroundColor(item);

        return <List.Item
            className={`service-ticket-workflow-list-item ph-10 border-radius-5 ${backgroundClass} ${onClick ? 'clickable' : ''}`}
            onClick={onClick}>
            <div className={'title-row'}>
                <Toolbar style={{padding: 0}} renderLeft={
                    () => <div>
                        <Link to={`/service/${item.id}`}>
                        <ServiceTicketTypeIcon
                            index={_.get(item, 'serviceTicketType.index')}/> [{item.key}] {item.name}
                        </Link>
                        <div className={'component-row'}>
                            {item.component ?
                                <div>
                                    <span>{item.component.name} {item.component.serialNo ? `(${item.component.serialNo})` : null}</span>
                                </div>
                                : <span>{t('No component linked')}</span>}
                        </div>
                    </div>
                } renderRight={() => {
                    const count = _.get(item, 'workflow.daysCount');
                    return <div className={"mt-5"}>
                        <span className={"border border-radius-10 p-5 workflow-badge"}>
                        <MaterialCommunityIcon name={_.get(item, 'workflow.deliveryReceivedComplete') ? 'package' : 'user'} />
                            {count < 999 ? count : "999+"}
                        </span>

                    </div>
                }}/>
            </div>

            <div className={'client-row'}>
                {item.client ?
                    <div>
                        <span className={'bold'}>{t('Client')}:</span> <span>{item.client.name}</span>
                    </div>
                    : <span>{t('No client')}</span>}
            </div>
            <div className={'service-action-row'}>
                {item.lastServiceAction ?
                    <div><span
                        className={'bold'}>{_.get(item, 'lastServiceAction.serviceActionType.name')}: {moment(_.get(item, 'lastServiceAction.insert')).format('LL')}</span>
                    </div>
                    : <span>{t('Last service action not available')}</span>
                }
            </div>

        </List.Item>

    }

    getBackgroundColor(item) {

        if (_.get(item, 'inQuarantineStore')) {
            return "danger-lighter-bg";
        }

        if (['createdQuotation', 'waitingForApprovingQuotation'].indexOf(_.get(item, 'quotationStatus')) > -1 && _.get(item, 'workflow.createdQuotation')) {
            return "warning-light-bg";
        }

        if (_.get(item, 'workflow.approvedQuotation', null)) {
            return 'success-lighter-bg';
        }

        return undefined;
    }
}

export default withTranslation()(ServiceTicketWorkFlowListItem);
