// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ProcessDetails from "../../graphql/queries/processDetails.graphql"

const ProcessBreadcrumb = ({processDetailsQuery, t}) =>
    <span>{_.get(processDetailsQuery, 'process.name', t('Unknown'))}</span>;

export default compose(
    graphql(ProcessDetails, {
        name: 'processDetailsQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.id')
            }
        })
    }),
)(withTranslation()(ProcessBreadcrumb));
