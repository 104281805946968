// @flow
import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";

type Props = {
    onProductExposed?: Function,
    defaultValue?: {value: string, name: string}
}

class ProductCreateForm extends Component<Props> {

    render() {

        const {t, onProductExposed, defaultValue} = this.props;

        const attributes = [
            {
                index: 'assetType',
                name: t('Asset type'),
                type: 'select',
                inputType: 'select',
                optionValues: [{name: t('Product'), value: 'product'}, {name: t('semiconTool'), value: 'tool'}, {name: t('semiconChamber'), value: 'chamber'}],
                value: defaultValue ?? 'product',
                rules: [{name: "required"}],
            },
            {
                index: 'name',
                name: t('Product name'),
                type: 'string',
                inputType: 'text',
                inputOptions: {
                    placeholder: t('Name of Product'),
                },
                rules: [{name: "required"}]
            },
            {
                index: 'catalogNo',
                name: t('Catalog No'),
                type: 'string',
                inputType: 'text',
                inputOptions: {
                    placeholder: t('Catalog No'),
                },
                rules: [{name: "required"}]
            },
            {
                index: 'manufacturerId',
                name: t('Manufacturer'),
                type: 'string',
                inputType: 'autocomplete',
                entityType: 'manufacturer',
                inputOptions: {
                    placeholder: t('Name of manufacturer'),
                },
                rules: [{name: "required"}],
                allowNoOption: true
            }
        ];


        return <AttributesInlineForm
            layout={'vertical'}
            clearApolloCache
            attributes={attributes}
            useSubmit
            translateMutationOptions={({values}) => (values)}
            mutation={onProductExposed}/>
    }
}


export default withTranslation()(ProductCreateForm);
