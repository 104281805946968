import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import AttributesInlineForm from "../Form/attributes-inline-form";
import _ from "lodash";
import {semiconComponentType} from "../ComponentDashboard";
import {graphql, withApollo} from "@apollo/client/react/hoc";
import componentTypeList from "../../graphql/queries/componentTypeList.graphql";
import {compose} from "recompose";
import withProfile from "../../hoc/withProfile";

type Props = {
    componentTypeIndex: string,
    onChange: Function,
    getComponentType: {
        loading: boolean,
        componentType: any,
    },
}

class ComponentTypeSelect extends Component<Props> {

    render() {
        const {defaultValue, onChange, componentTypeIndex, componentTypeList, t} = this.props;

        const blacklist = ['trashPool', 'globalTrashPool'];
        const componentTypes = _.get(componentTypeList, 'componentTypeList.componentTypes',[]).filter((item) => {
            return blacklist.indexOf(item.index) === -1 &&
                ((!item.product || item.index === "machine" || item.index === "componentProduct") || semiconComponentType.indexOf(item.index) !== -1)
        });

        let componentTypeValues = componentTypes.map(item => {
            return {
                name: item.name,
                value: item.index
            };
        })

        let componentTypeAttribute = [{
            index: 'componentType',
            name: t('Infrastructure Unit'),
            type: 'select',
            inputType: 'select',
            optionValues: componentTypeValues,
            value: componentTypeValues.find(item => item.value === componentTypeIndex),
            // rules: [{name: "required"}],
        }]

        return <AttributesInlineForm
            layout={'vertical'}
            useSubmit={false}
            attributes={componentTypeAttribute}
            onChange={(item) => onChange(item)}
        />
    }

}

export default compose(
    withTranslation(),

    graphql(componentTypeList, {
        name: 'componentTypeList',
    }),

    withProfile(),
    withApollo,
)(ComponentTypeSelect);