// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import ComponentTypeList from "./../ComponentTypeList";
import {compose} from "recompose";
import {semiconComponentType} from "../ComponentDashboard";

type Props = {
    t: Function,
    onSelectComponentType: ?Function,
    titleSize?: 'medium' | 'large',
}

class ComponentCreateComponentTypes extends Component<Props> {

    render() {

        const {t, onSelectComponentType, titleSize} = this.props;
        const semiconTypes = ['semiconductorFab', ...semiconComponentType];
        const stockTypes = ['stock', 'shelf', 'box'];
        const blacklist = ['componentProduct', 'trashPool', 'globalTrashPool'];

        return <div tour-id="createComponent__newUnit">
            <ComponentTypeList
                style={{marginBottom: 8}}
                title={t('General')}
                titleSize={titleSize}
                filter={(item) => {
                    return stockTypes.indexOf(item.index) === -1
                        && blacklist.indexOf(item.index) === -1
                        && semiconTypes.indexOf(item.index) === -1
                        && !item.product || item.index === "machine"
                }}
                onPress={(item) => onSelectComponentType(item.index)}
            />

            <ComponentTypeList
                title={t('Semiconductor')}
                titleSize={titleSize}
                filter={(item) => {
                    return stockTypes.indexOf(item.index) === -1
                        && blacklist.indexOf(item.index) === -1
                        && semiconTypes.indexOf(item.index) !== -1
                }}
                onPress={(item) => onSelectComponentType(item.index)}
            />

            <ComponentTypeList
                title={t('Stock')}
                titleSize={titleSize}
                filter={(item) => {
                    return stockTypes.indexOf(item.index) !== -1
                        && blacklist.indexOf(item.index) === -1
                        && (!item.product || item.index === "machine")
                }}
                onPress={(item) => onSelectComponentType(item.index)}
            />
        </div>
        ;
    }

}

export default compose(
    withTranslation(),
    withRouter
)(ComponentCreateComponentTypes);
