// @flow
import React, {Component} from "react"
import ComponentCreateForm from "../../components/ComponentCreate/ComponentCreateForm";
import _ from "lodash";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Panel from "../../components/Panel";
import {defaultSubcomponentConfig} from "../../utils/defaultSubcomponentConfig";
import {Col} from "antd";

type Props = {
    match: {
        params: {
            id: string
        }
    },
    history: {
        push: Function
    }
}

class InfrastructureCreateComponentDetails extends Component<Props> {
    replaceUrl = null;

    constructor(props) {
        super(props);

        this.replaceUrl = `/infrastructure/create/${this.props.match.params.id}/details`;
    }

    handleAddSubcomponent = (id, componentTypeIndex) => {
        const defaultSubComponentType = defaultSubcomponentConfig[componentTypeIndex]
        const search = defaultSubComponentType ? `?componentTypeIndex=${defaultSubComponentType}` : '';

        return this.props.history.push(`/infrastructure/create/${id}/subcomponent${search}`);
    }

    render() {

        const {match, history} = this.props;
        const componentId = _.get(match, 'params.id');

        const search = _.get(this.props, 'location.search');
        const params = queryString.parse(search) || {};
        const product = queryString.parse(params.product) || {};

        const onComponentTypeChange = (index) => {
            params.componentTypeIndex = index.componentType;
            const stringified = queryString.stringify(params);
            history.replace(`${this.replaceUrl}?${stringified}`);
        }

        return <Col lg={{span: 16, offset: 4}}
                    xl={{span: 14, offset: 5}}
                    xxl={{span: 12, offset: 6}}>
            <Panel>
                <ComponentCreateForm
                    componentTypeIndex={params.componentTypeIndex}
                    onComponentTypeChange={onComponentTypeChange}
                    parentComponentId={componentId}
                    product={product}
                    productId={params.productId}
                    onCreated={(res, subcomponent) => {
                        const id = _.get(res, 'data.createComponent.id');
                        if (id) {
                            if (subcomponent) {
                                this.handleAddSubcomponent(id, params.componentTypeIndex);
                                return;
                            }
                            history.push(`/infrastructure/${id}`)
                        }
                    }}
                />
            </Panel>
        </Col>;
    }

}

export default withRouter(withTranslation()(InfrastructureCreateComponentDetails));
