// @flow
import React, {Component} from "react"
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import queryString from "query-string";
import ComponentCreateSubcomponentForm from "../../components/ComponentCreate/ComponentCreateSubcomponentForm";
import {Col} from "antd";
import Panel from "../../components/Panel";

type Props = {
    match: {
        params: {
            id: string
        }
    },
    history: {
        push: Function
    }
}

class InfrastructureCreateComponentSubcomponentRoute extends Component<Props> {
    replaceUrl = null;

    constructor(props) {
        super(props);

        this.replaceUrl = `/infrastructure/create/${this.props.match.params.id}/subcomponent`;
    }

    render() {
        const {history} = this.props;

        const search = _.get(this.props, 'location.search');
        const params = queryString.parse(search) || {};

        const onComponentTypeChange = (index) => {
            params.componentTypeIndex = index;
            const stringified = queryString.stringify(params);
            history.replace(`${this.replaceUrl}?${stringified}`);
        }

        const parentId = _.get(this.props, 'match.params.id');

        return <Col
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 14, offset: 5 }}
          xxl={{ span: 12, offset: 6 }}
        >
            <Panel>
                <ComponentCreateSubcomponentForm
                    key={Date.now()}
                    parentComponentId={parentId}
                    componentTypeIndex={params.componentTypeIndex}
                    onComponentTypeChange={onComponentTypeChange}
                    onCreated={(res,addSubcomponent) => {
                            if (addSubcomponent) {
                                history.push(`/infrastructure/create/${parentId}/subcomponent?componentTypeIndex=${params.componentTypeIndex}`);
                                return;
                            }
                            history.push(`/infrastructure/${parentId}`)
                    }}
                />
            </Panel>
        </Col>;
    }

}

export default withRouter(withTranslation()(InfrastructureCreateComponentSubcomponentRoute));
