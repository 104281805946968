// @flow
import React from 'react';
import _ from "lodash";
import { graphql } from '@apollo/client/react/hoc';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ProcessFamilyDetails from "../../graphql/queries/processFamilyDetails.graphql"

const ProcessFamilyBreadcrumb = ({processFamilyDetailsQuery, t}) =>
    <span>{_.get(processFamilyDetailsQuery, 'processFamily.name', t('Unknown'))}</span>;

export default compose(
    graphql(ProcessFamilyDetails, {
        name: 'processFamilyDetailsQuery',
        options: ({match}) => ({
            variables: {
                id: _.get(match, 'params.id')
            }
        })
    }),
)(withTranslation()(ProcessFamilyBreadcrumb));
