// @flow
import React from "react"
import {useTranslation} from "react-i18next";
import _ from "lodash";
import ProcessQuery from "../../graphql/queries/processForm.graphql";
import ProcessUpdateMutation from "../../graphql/mutations/updateProcess.graphql";
import AttributeInlineForm from "./../Form/attributes-inline-form";
import Sizes from "../../themes/default/sizes";
import processDetails from "../../graphql/queries/processDetails.graphql";
import {useMutation, useQuery} from "@apollo/client";
import {Loader} from "@dreebit/react-components";

const blacklist = [];

type Props = {
    id: any
};

export const ProcessForm = ({id}: Props) => {
    const {t} = useTranslation();
    const processForm = useQuery(ProcessQuery, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: id
        }
    });
    const [processUpdate] = useMutation(ProcessUpdateMutation, {
        refetchQueries: [{
            query: processDetails,
            variables: {
                id: id
            }
        }]
    });

    if (processForm.loading) return <Loader/>;

    return <AttributeInlineForm
        mutation={processUpdate}
        translateMutationOptions={(formValues: any) => {
            const {changes, attributes}: any = formValues;

            if (changes.optionValues) {
                const optionValuesAttribute = _.find(attributes, {index: 'optionValues'});
                const nameAttribute = _.find(optionValuesAttribute.attributes, {index: 'name'});
                if (nameAttribute) {
                    changes.optionValues = changes.optionValues.map((optionValue) => {

                        const defaultTranslationLang = _.first(Object.keys(optionValue.name));
                        return {
                            ...optionValue,
                            name: nameAttribute.languages.map((language) => language.value).reduce((acc, languageKey) => {
                                if (!acc[languageKey]) {
                                    acc[languageKey] = optionValue["name"][languageKey] || optionValue["name"][defaultTranslationLang]
                                }
                                return acc;
                            }, optionValue.name)
                        }
                    })
                }
            }

            return {
                variables: {
                    input: changes,
                    id: id,
                }
            }
        }}
        submitButtonWrapperStyle={{textAlign: "right", marginRight: Sizes.grid * 2}}
        attributes={
            _.get(processForm, 'data.process.typeDefinitionAttributeList.typeDefinitionAttributes', [])
                .filter(item => blacklist.indexOf(item.index) === -1)
                .map(item => {
                    const attribute = {
                        ...item,
                    };
                    return attribute;
                })
        }
    />
};
