// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {Card, Col, Row, Spin, Tooltip} from "antd";
import componentTypeList from './../../graphql/queries/componentTypeList.graphql';
import ComponentTypeIcon from "../ComponentTypeIcon";

type Props = {
    title: string,
    getComponentTypeList: {
        loading: boolean,
        componentTypeList: ?any
    },
    filter: ?Function,
    onPress: ?Function,
    style: ?any,
    titleSize: 'medium' | 'large',
}

class ComponentTypeList extends Component<Props> {

    render() {

        const {title, getComponentTypeList, filter, onPress, style, titleSize} = this.props;

        let data = [];
        if (getComponentTypeList.componentTypeList && getComponentTypeList.componentTypeList.componentTypes) {
            data = getComponentTypeList.componentTypeList.componentTypes;
            if (filter) {
                data = data.filter(filter);
            }
        }

        return (
            <>
                {titleSize === 'large' ? <h3>{title}</h3> : <h4>{title}</h4>}
                <Row gutter={16}>
                    {getComponentTypeList.loading ? (
                        <Row justify={'center'} style={{width:'100%'}}>
                            <Spin/>
                        </Row>
                    ) : data.map((item) => (
                        <Col span={8} key={item.index}>
                            <Card
                                loading={getComponentTypeList.loading}
                                onClick={() => {
                                    if (onPress) {
                                        onPress(item)
                                    }
                                }}
                                style={{...style, marginBottom: '16px', borderRadius: '6px', cursor: 'pointer'}}
                            >
                                <Tooltip title={item.description} placement="bottomLeft">
                                    <div className="flex-row" style={{width: "100%"}}>
                                        <ComponentTypeIcon size={24} componentTypeIndex={item.index} color={'black'}/>
                                        <span style={{marginLeft: 8, marginTop: 2, fontSize: 14}}>{item.name}</span>
                                    </div>
                                </Tooltip>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </>
        );
    }

}

ComponentTypeList.defaultProps = {
    titleSize: 'large'
};

export default compose(
    graphql(componentTypeList, {
        name: 'getComponentTypeList',
    }),
)(ComponentTypeList);
