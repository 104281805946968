import React from 'react';
import {NavLink} from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import {withTranslation} from "react-i18next";
import _ from "lodash";
import {Breadcrumb} from "antd";
import TicketBreadcrumb from "./ticket";
import StockBreadcrumb from "./stock";
import ServiceplanBreadcrumb from "./serviceplan";
import ClientBreadcrumb from "./client";
import AccessRequestsBreadcrumb from "./accessRequests";
import ServiceProviderBreadcrumb from "./serviceProvider";
import ServiceCenterBreadcrumb from "./serviceCenter";
import ServiceDepartmentBreadcrumb from "./serviceDepartment";
import ServiceReportBreadcrumb from "./serviceReport";
import ConnectorsBreadcrumb from "./connectors";
import NotificationSettingsBreadcrumb from "./notificationSettings";
import NewsBreadcrumb from "./news";
import LogisticianBreadcrumb from "./logistician";
import CustomAttributeBreadcrumb from "./customAttribute";
import ComponentWithSubcomponentsBreadcrumb from "./componentWithSubcomponents";
import servicecontrolsBreadcrumb from './servicecontrolsBreadcrumb';
import serviceActionsBreadcrumb from './serviceActionsBreadcrumb';
import DashboardBreadcrumb from "./dashboardSetting";
import ServiceContractsBreadcrumb from "./serviceContracts";
import ComponentVersionalAttributeBreadcrumb from "./versionalAttribute";
import HostBreadcrumb from "./host";
import ServiceActionTypeBreadcrumb from "./serviceActionType";
import './index.css';
import ProcessBreadcrumb from "./process";
import ProcessFamilyBreadcrumb from "./processFamily";

// define some custom breadcrumbs for certain routes (optional)
const routes = [
    {path: '/dashboard/:dashboardId', breadcrumb: DashboardBreadcrumb},
    {path: '/service/:serviceTicketId/contaminationDeclaration', breadcrumb: 'Contamination Declaration'},
    {path: '/infrastructure/:componentId/serviceplans/:servicePlanId', breadcrumb: ServiceplanBreadcrumb},
    {path: '/service/:serviceTicketId', breadcrumb: TicketBreadcrumb},
    {path: '/service/:serviceTicketId/serviceReports/create', breadcrumb: 'Create'},
    {path: '/service/:serviceTicketId/serviceReports/:serviceReportId', breadcrumb: ServiceReportBreadcrumb},
    {path: '/service/:serviceTicketId/serviceReports', breadcrumb: 'Service Reports'},
    {path: '/infrastructure', breadcrumb: null},
    {path: '/infrastructure/create', breadcrumb: null},
    {path: '/infrastructure/cmt', breadcrumb: 'Infrastructure'},
    {path: '/infrastructure/cmt/:token', breadcrumb: 'Token'},
    {path: '/infrastructure/dashboard', breadcrumb: 'Overview'},
    {path: '/infrastructure/create/details', breadcrumb: null},
    {path: '/infrastructure/create/:id', breadcrumb: ComponentWithSubcomponentsBreadcrumb},
    {path: '/infrastructure/stock', breadcrumb: 'Stock management'},
    {path: '/infrastructure/stock/dashboard', breadcrumb: null},
    {path: '/infrastructure/stock/:componentId', breadcrumb: StockBreadcrumb},
    {path: '/infrastructure/:componentId', breadcrumb: ComponentWithSubcomponentsBreadcrumb},
    {path: '/infrastructure/:componentId/servicecontrols/:serviceControlId', breadcrumb: servicecontrolsBreadcrumb},
    {path: '/infrastructure/:componentId/versionalattributes/:index', breadcrumb: ComponentVersionalAttributeBreadcrumb},
    {path: '/service', breadcrumb: 'Tickets'},
    {path: '/service', breadcrumb: 'Service'},
    {path: '/service/:id/actions/:serviceActionId', breadcrumb: serviceActionsBreadcrumb},
    {path: '/contractpartners/:id', breadcrumb: ServiceContractsBreadcrumb},
    {path: '/admin', breadcrumb: null},
    {path: '/admin/hosts/:hostId', breadcrumb: HostBreadcrumb},
    {path: '/admin/clients/new', breadcrumb: 'Create'},
    {path: '/admin/clients/search', breadcrumb: null},
    {path: '/admin/clients/:clientId', breadcrumb: ClientBreadcrumb},
    {path: '/admin/clients', breadcrumb: 'Clients'},
    {path: '/admin/accessrequests/:clientId', breadcrumb: AccessRequestsBreadcrumb},
    {path: '/admin/accessrequests', breadcrumb: 'Access requests'},
    {path: '/admin/logisticians/new', breadcrumb: 'Create'},
    {path: '/admin/serviceProviders/new', breadcrumb: "Create"},
    {path: '/admin/serviceproviders/:id', breadcrumb: ServiceProviderBreadcrumb},
    {path: '/admin/servicecenters', breadcrumb: 'Service Center'},
    {path: '/admin/servicecenters/new', breadcrumb: 'Create'},
    {path: '/admin/servicecenters/:id', breadcrumb: ServiceCenterBreadcrumb},
    {path: '/admin/servicecenters/:id/servicedepartments', breadcrumb: null},
    {
        path: '/admin/servicecenters/:id/servicedepartments/:serviceDepartmentId',
        breadcrumb: ServiceDepartmentBreadcrumb
    },
    {path: '/profile/connectors/:id', breadcrumb: ConnectorsBreadcrumb},
    {path: '/profile/notificationsettings/create', breadcrumb: 'Create'},
    {path: '/profile/notificationsettings/:id', breadcrumb: NotificationSettingsBreadcrumb},
    {path: '/admin/news/:id', breadcrumb: NewsBreadcrumb},
    {path: '/admin/logisticians/detail', breadcrumb: null},
    {path: '/admin/logisticians/detail/:id', breadcrumb: LogisticianBreadcrumb},
    {path: '/admin/attributes/new', breadcrumb: 'Create'},
    {path: '/admin/attributes/:index', breadcrumb: CustomAttributeBreadcrumb},
    {path: '/admin/processes/new', breadcrumb: 'Create'},
    {path: '/admin/processes/:id', breadcrumb: ProcessBreadcrumb},
    {path: '/admin/processFamilies/new', breadcrumb: 'Create'},
    {path: '/admin/processFamilies/:id', breadcrumb: ProcessFamilyBreadcrumb},
    {path: '/admin/users', breadcrumb: 'Users'},
    {path: '/admin/users/detail/:id', breadcrumb: null},
    {path: '/', breadcrumb: null},
    {path: '/admin/serviceactionstypes/:index', breadcrumb: ServiceActionTypeBreadcrumb},
];

// map & render your breadcrumb components however you want.
// each `breadcrumb` has the props `key`, `location`, and `match` included!
const RouterBreadcrumbs = ({breadcrumbs, t, style}) => {

        return <Breadcrumb style={style}>
            {breadcrumbs.map((breadcrumb) => {

                const path = _.get(breadcrumb, 'props.match.path', null);
                if (path === '/infrastructure/stock/:componentId') {
                    return breadcrumb;
                }

                return <Breadcrumb.Item key={breadcrumb.key}>
                    <NavLink to={breadcrumb.props.match.url}>
                        {_.isString(_.get(breadcrumb, 'props.children')) ? t(breadcrumb.props.children) : breadcrumb}
                    </NavLink>
                </Breadcrumb.Item>
            })}
        </Breadcrumb>
    }
;


export default withTranslation()(withBreadcrumbs(routes)(RouterBreadcrumbs));
