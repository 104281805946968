// @flow
import React, {Component, Suspense} from "react"
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'
import {CookiesProvider} from 'react-cookie';
import {FormAttributesProvider, WebRenderFactory} from '@dreebit-registry/form-attributes-vsm';
import store from '../../store/store';
import App from '../../components/App/App';
import {withTranslation} from 'react-i18next';
import i18n from '../../i18n';
import VSMLoadingAnimation from "../../components/VSMLoadingAnimation";
import createInputTypeFactory from "../../components/FormAttributes/customInputTypes";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import GraphQLProvider from "../../graphql/provider";
import ThemeProvider from "./../../provider/theme";
import {ConfigProvider} from "antd";

import deDE from "antd/lib/locale-provider/de_DE";
import enGB from "antd/lib/locale-provider/en_GB";

i18n();

export let GLOABL_PERSISTOR = null;

class Root extends Component {

    state = {
        loading: true
    };

    constructor(props) {
        super(props);

        this.persistor = persistStore(store, null, () => {

            console.log(
                'Store loaded',
                store
            )
            this.setLoading(false);

        });
        GLOABL_PERSISTOR = this.persistor;
    }

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };

    render() {
        const {loading, beConfig} = this.state;
        const {i18n, t} = this.props;

        if (!i18n || loading) {
            return <VSMLoadingAnimation/>
        }

        const inputTypeMap = createInputTypeFactory();

        return <ThemeProvider>
            <CookiesProvider>
                <DndProvider backend={HTML5Backend}>
                    <PersistGate persistor={this.persistor}>
                        <Provider store={store}>
                            <ConfigProvider
                                locale={i18n.language === 'de' ? deDE : enGB}
                            >
                                <GraphQLProvider>
                                    <FormAttributesProvider.Provider value={{
                                        renderFactory: new WebRenderFactory(inputTypeMap),
                                        translations: {
                                            t,
                                            language: i18n.language,
                                            "decimalSeparator": t("decimalSeparator"),
                                            "thousandSeparator": t("thousandSeparator"),
                                            "Yes": t("Yes"),
                                            "No": t("No"),
                                            "showMore": t("Show more"),
                                            "showLess": t("Show less"),
                                            "confirmTitle": t("Are you sure"),
                                            "Please select": t("Please select"),
                                            "Search": t("Search"),
                                            "Submit": t("Submit"),
                                            "Cancel": t("Cancel"),
                                            "New": t("New"),
                                            "Delete": t("Delete"),
                                            "Click or drag file to this area to upload": t("Click or drag file to this area to upload"),
                                            "Support for a single or bulk upload": t("Support for a single or bulk upload"),
                                            "file uploaded successfully": t("file uploaded successfully"),
                                            "year(s)": t('year(s)'),
                                            "month(s)": t('month(s)'),
                                            "day(s)": t('day(s)'),
                                            "hour(s)": t('hour(s)'),
                                            "Edit Row": t("Edit Row"),
                                            "Add Row": t('Add Row'),
                                            "Add": t('Add'),
                                            "Preview": t('Preview'),
                                            "Upload": t('Upload')
                                        }
                                    }}>
                                        <App/>
                                    </FormAttributesProvider.Provider>
                                </GraphQLProvider>
                            </ConfigProvider>
                        </Provider>
                    </PersistGate>
                </DndProvider>
            </CookiesProvider>
        </ThemeProvider>
    }
}

const WrappedRoot = withTranslation()(Root);

export default () => {
    return <Suspense fallback={<VSMLoadingAnimation/>}>
        <WrappedRoot/>
    </Suspense>
};
