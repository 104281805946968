import React from 'react';

const ToolIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path className="st0" d="M19.4,15.3c-0.6,0-1.1,0.2-1.6,0.4l-0.4-0.4c0.7-0.9,1.1-2.1,1.1-3.3c0-1.2-0.4-2.4-1.1-3.3l0.5-0.5L18,8.1
		c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l1.7-1.7c0.8-0.8,0.8-2,0-2.8L21,2.1c-0.8-0.8-2.1-0.8-2.8,0l-1.7,1.7c-0.8,0.8-0.8,2,0,2.8
		l0.1,0.1L16,7.2c-0.9-0.7-2.1-1.1-3.3-1.1c-0.5,0-1,0.1-1.5,0.2l-0.4-1c0.5-0.5,0.9-1.3,0.9-2.1c0-1.6-1.3-2.9-2.9-2.9
		S5.7,1.7,5.7,3.3S7,6.2,8.6,6.2c0.1,0,0.2,0,0.3,0l0.5,1c-0.6,0.5-1.2,1-1.6,1.7L6.8,8.5c0,0,0-0.1,0-0.1c0-1.6-1.3-2.9-2.9-2.9
		S0.9,6.8,0.9,8.4s1.3,2.9,2.9,2.9c0.9,0,1.6-0.4,2.2-1L7,10.7c-0.1,0.4-0.1,0.8-0.1,1.2c0,1.2,0.4,2.4,1.1,3.3l-0.5,0.5l-1.4-1.4
		c-0.8-0.8-2.1-0.8-2.8,0l-1.7,1.7c-0.4,0.4-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4l4.1,4.1c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l1.7-1.7
		h0c0.8-0.8,0.8-2,0-2.8l-1.4-1.4l0.5-0.5c0.9,0.7,2.1,1.1,3.3,1.1c1.2,0,2.4-0.4,3.3-1.1l0.4,0.4c-0.3,0.5-0.4,1-0.4,1.6
		c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4C22.8,16.8,21.3,15.3,19.4,15.3z M18.7,7.4l0.7-0.7c0,0,0,0,0,0L18.7,7.4z M19.5,3.5L21.1,5
		l-1.7,1.7l-1.5-1.5L19.5,3.5z M7.7,3.3c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S9.1,4.2,8.6,4.2S7.7,3.8,7.7,3.3z M3.9,9.3
		c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S4.4,9.3,3.9,9.3z M7.1,21.6l-4.1-4.1l1.7-1.7l4.1,4.1L7.1,21.6z
		 M8.8,11.9c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8c0,2.1-1.7,3.8-3.8,3.8S8.8,14,8.8,11.9z M19.4,20.1c-0.8,0-1.4-0.6-1.4-1.4
		c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C20.8,19.4,20.2,20.1,19.4,20.1z"/>
    </svg>
);

export default ToolIcon;