// @flow
import React, {Component} from 'react';
import MultiActionWrapper from "../MultiActionWrapper";
import TDAAttributesForm from "../TDAAttributesForm";
import {compose, withProps} from "recompose";
import {graphql, withApollo} from "@apollo/client/react/hoc";
import FinalTestAttributesQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import _ from "lodash";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {withTranslation} from "react-i18next";
import CreateFinalTestMutation from "../../graphql/mutations/createFinalTest.graphql";
import UpdateFinalTestMutation from "../../graphql/mutations/UpdateFinalTestMutation.graphql"

class ServiceTicketsAddFinalTest extends Component {
  componentDidMount() {}

  render() {
    const {serviceTicketIds, attributes, t, onFinished, client} = this.props;

    return <MultiActionWrapper
      onFinished={onFinished}
      total={serviceTicketIds.length}
      mutation={(item) => {

        return client.mutate({
          mutation: CreateFinalTestMutation,
          variables: {
            serviceTicketId: item.variables.serviceTicketId,
            attributes: item.variables.attributes
          }
        }).then(({data})=>{
          if (!data.createFinalTest.id){
            client.mutate({
              mutation: UpdateFinalTestMutation,
              variables: {
                serviceTicketId: item.variables.serviceTicketId,
                attributes: item.variables.attributes
              }
            })
          }
        })
      }}
    >
      {({queue}) => {
        return <TDAAttributesForm

          disableToasts
          attributes={attributes}
          translateMutationOptions={({values}) => {
            return values
          }}
          mutation={(changes) => {
            return new Promise((resolve, reject) => resolve(changes));
          }}
          submitTitle={t("addToServicetickets", {count: serviceTicketIds.length})}
          onSubmit={(values) => {
            serviceTicketIds.forEach((serviceTicketId) => {
              queue.push({
                variables: {serviceTicketId: serviceTicketId, attributes: values}
              })
            })
          }}
        />
      }}
    </MultiActionWrapper>
  }
}

export default compose(
  graphql(FinalTestAttributesQuery, {
    name: 'finalTestAttributesQuery',
    options: (props) => ({
      variables: {
        index: 'finalTest'
      }
    })
  }),
  waitWhileLoading('finalTestAttributesQuery','typeDefinitionAttributeList.typeDefinitionAttributes'),
  graphql(CreateFinalTestMutation, {
    name: 'createFinalTestMutation'
  }),
  graphql(UpdateFinalTestMutation, {
    name: 'updateFinalTestMutation'
  }),
  withTranslation(),
  withProps((props) => ({
    attributes: _.get(props.finalTestAttributesQuery,'typeDefinitionAttributeList.typeDefinitionAttributes',[])
  })),
  withApollo
)(ServiceTicketsAddFinalTest);
