// @flow
import React from "react";
import ServiceTicketStateBadge from "../../ServiceTicketStateBadge";
import {Link} from "react-router-dom";
import ServiceTicketTypeIcon from "../../ServiceTicketTypeIcon";
import _ from "lodash";
import ComponentPathBreadcrumb, {getPathText} from "../../ComponentPathBreadcrumb";
import OneLineLabel from "../../OneLineLabel";
import {Tooltip} from "antd";
import ComponentImage from "../../ComponentImage";

export const getColumns = (type: 'serviceTicket' | 'component', t: Function) => {
    if(type === 'serviceTicket'){
        return [
            {
                title: t('State'),
                dataIndex: 'serviceTicketState.index',
                key: 'serviceTicketState.index',
                render: (text: any, record: any) => {
                    return <ServiceTicketStateBadge state={record.serviceTicketState} />
                }
            },
            {
                title: t('Key'),
                dataIndex: 'key',
                key: 'key',
                width: 150,
                useSort: true,
                render: (text, record) => <Link
                    className={"text-link"}
                    to={`/service/${record.id}`}><ServiceTicketTypeIcon index={_.get(record,'serviceTicketType.index')}/> {text}</Link>
            },
            {
                title: t('Component'),
                dataIndex: 'component.name',
                key: 'component.name',
                width: 200,
                render: (text, record) => {
                    const read = _.get(record, 'component.access.read', true);
                    const path = _.get(record, 'component.path', []);
                    const pathText = getPathText(path);
                    const label = <Link
                        className={"text-link"}
                        to={`/infrastructure/${_.get(record, 'component.id')}`}>{_.get(record, 'component.name')}</Link>

                    if(read){
                        if (path.length > 1) {
                            return <OneLineLabel><Tooltip title={pathText}>
                                {label}
                            </Tooltip></OneLineLabel>
                        }

                        return label;
                    }
                    return _.get(record, 'component.name')
                },
            },
            {
                key: 'component.serialNo',
                dataIndex: 'component.serialNo',
                title: t('Serial No')
            },
            {
                key: 'component.product.catalogNo',
                dataIndex: 'component.product.catalogNo',
                title: t('Catalog No')
            },
            {
                key: 'client.id',
                dataIndex: 'client.name',
                width: 150,
                title: t('Client')
            }
        ];
    }
    if(type === 'component'){
        return [
            {
                key: 'image.thumbnailLink',
                dataIndex: 'image.thumbnailLink',
                width: 50,
                render: (text, record) => {
                    return <Link to={`/infrastructure/${record.id}`}><ComponentImage size={30} link={text}/></Link>
                }
            }, {
                key: 'name',
                dataIndex: 'name',
                title: t('Name'),
                sorter: true,
                width: 500,
                render: (text, record) => {
                    return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: 500 }}>
                        <Link to={`/infrastructure/${record.id}`}>
                            {text}
                        </Link>
                        <div style={{maxWidth: 500}}>
                            <OneLineLabel >
                                <ComponentPathBreadcrumb hideLastItem path={record.path}/>
                            </OneLineLabel>
                        </div>
                    </div>
                }
            }, {
                key: 'product.manufacturer.name',
                dataIndex: 'product.manufacturer.name',
                width: 150,
                title: t('Manufacturer'),
                renderItem: (text, record) => {
                    let link = <Link to={`/infrastructure/${record.id}`}><div  style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{text}</div></Link>
                    if (!text && record.manufacturerName) {
                        link = <Link to={`/infrastructure/${record.id}`}><div  style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{record.manufacturerName}</div></Link>;
                    }
                    return <div  style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        {link}
                    </div>
                }
            },{
                key: 'client.id',
                dataIndex: 'client.name',
                width: 150,
                title: t('Client')
            },{
                key: 'serialNo',
                width: 150,
                dataIndex: 'serialNo',
                title: t('Serial No'),
            },{
                key: 'product.catalogNo',
                dataIndex: 'product.catalogNo',
                width: 150,
                title: t('Catalog No'),
                render: (text, item) => {
                    if (!text && item.catalogNo) {
                        return item.catalogNo;
                    }
                    return text
                }
            }
        ];
    }
};
