// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import TypeDefinitionAttributesForm from "./../TypeDefinitionAttributesForm";
import CreateProcessMutation from "./../../graphql/mutations/createProcess.graphql";
import processList from "../../graphql/queries/processList.graphql";

type Props = {
    onCreate: Function,
};

class ProcessCreate extends Component<Props, State> {

    render() {
        const {createProcessMutation} = this.props;

        return <TypeDefinitionAttributesForm
            index="process"
            formProps={{
                mutation: createProcessMutation,
                onSubmit: (res) => {
                    if (this.props.onCreate) {
                        this.props.onCreate(res);
                    }
                },
                translateMutationOptions: ({values}) => {
                    // if(!values.name.en){
                    //     values.name.en = values.name.de
                    // }
                    // if(!values.name.de){
                    //     values.name.de = values.name.en
                    // }
                    return {
                        variables: {
                            input: values
                        }
                    }
                }
            }}
        />
    }
}

export default compose(
    graphql(CreateProcessMutation, {
        name: 'createProcessMutation',
        options: () => ({
            refetchQueries: [{
                query: processList,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        })
    }),
)(ProcessCreate);
