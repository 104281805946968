// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import processFamilyList from "./../../graphql/queries/processFamilyList.graphql"
import waitWhileLoading from "../../hoc/waitWhileLoading";
import FillQueryTable from "../QueryTable/fill";
import withProfile from "../../hoc/withProfile";

type Props = {
    processFamilyListQuery: query
}

class ProcessFamilyList extends Component<Props> {

    render() {

        const {processFamilyListQuery, history, t} = this.props;

        return <FillQueryTable
            style={{borderRadius: 0}}
            className={"link-table"}
            columns={[
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: t('Name')
                },
                {
                    key: 'description',
                    dataIndex: 'processFamily.description',
                    title: t('Description'),
                },
            ]}
            onRow={(record) => {
                return {
                    onClick: () => {
                        history.push(`/admin/processFamilies/${record.id}`)
                    },
                };
            }}
            size={'small'}
            rowKey={"index"}
            uniqueIdentifier={"index"}
            query={processFamilyListQuery}
            limitKeyPath={'params.limit'}
            startKeyPath={'params.start'}
            itemsKeyPath={'processFamilyList.processFamilies'}
            totalKeyPath={'processFamilyList.total'}/>
    }
}

export default compose(
    withProfile(),
    withRouter,
    withTranslation(),
    graphql(processFamilyList, {
        name: 'processFamilyListQuery',
        options: (props) => ({
            variables: {
                params: {
                    start: 0,
                    limit: 40,
                    filter: props.filter
                }
            }
        }),
    }),
    waitWhileLoading('processFamilyListQuery', 'processFamilyList.processFamilies')
)(ProcessFamilyList);
