// @flow
import React, {Component} from "react";
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import _ from 'lodash'
import {withTranslation} from "react-i18next";
import createComponent from "../../graphql/mutations/createComponent.graphql"
import AttributesInlineForm from "../Form/attributes-inline-form";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {Checkbox, Col, Row, Button} from "antd";
import ComponentInfoBox from "../ComponentInfoBox";
import Label from "../Utilities/Label";
import TypeDefinitionAttributeListQuery from "../../graphql/queries/typeDefinitionAttributeList.graphql";
import withProfile from "../../hoc/withProfile";
import isServiceProvider from "../../utils/isServiceProvider";
import {CloseOutlined} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import ComponentTypeSelect from "./ComponentTypeSelect";
import ComponentCreateComponentTypes from "./ComponentCreateComponentTypes";

type Props = {
    parentComponentId: string,
    componentTypeIndex?: string,
    onCreated: Function,

    getProduct: {
        loading: boolean,
        product: any,
    },
    createComponent: Function,
    t: Function,
    onComponentTypeChange: Function,
}

class ComponentCreateSubcomponentForm extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            addSubcomponent: false
        };
    }

    values = {};

    render() {

        const {
            attributeQuery,
            t,
            createComponent,
            parentComponentId,
            onCreated,
            client,
            history,
            onComponentTypeChange,
            componentTypeIndex
        } = this.props;
        const {addSubcomponent} = this.state;

        let productObject = null;

        const attributes = _.get(attributeQuery, 'typeDefinitionAttributeList.typeDefinitionAttributes', []);
        const mainFields = ["name", "serialNo", ...attributes.filter(item => _.findIndex(item.rules, {name: 'required'}) !== -1).map(item => item.index)];

        if (isServiceProvider(this.props.resources)) {
            mainFields.push("clientId");
        }

        const handleClose = () => history.push(`/infrastructure/${parentComponentId}`);

        return <Col className={'ph-10 pt-10'}>
            <Row className={'pb-15'} justify={'space-between'} align={"top"}>
                <h3 className={'ph-5'}>{t('Add Subcomponent')}</h3>
                <CloseOutlined style={{fontSize: 20}} onClick={handleClose}/>
            </Row>
            <Label>{t('Parent Node')}</Label>
            <div className={'lighter-grey-bg border-radius-5 border'} style={{marginBottom: '24px'}}>
                <ComponentInfoBox
                    componentId={parentComponentId}/>
            </div>
            {attributes.length > 0 ?
                <>
                    <Label>{t('Details')}</Label>
                    <ComponentTypeSelect
                        componentTypeIndex={componentTypeIndex}
                        onChange={(item) => {
                            onComponentTypeChange(item.componentType);
                        }}
                    />
                </> :
                <>
                    <h3 className={'ph-5'}>{t('Select an infrastructure unit')}</h3>
                    <ComponentCreateComponentTypes titleSize={'medium'} onSelectComponentType={(index) => {
                        onComponentTypeChange(index);
                    }}/>
                </>
            }
            <AttributesInlineForm
                useSubmit
                layout={'vertical'}
                collapse={mainFields}
                mutation={(options) => {
                    return new Promise((resolve, reject) => {
                        createComponent(options)
                            .then((res) => {
                                return client.clearStore().then(() => res);
                            })
                            .then(resolve)
                            .catch(reject)
                    })
                }}
                onSubmit={(res) => onCreated(res, addSubcomponent)}
                translateMutationOptions={({values}) => {
                    const productIdFromValues = _.get(values, 'productId')

                    Object.keys(values).map((key) => {
                        if (values[key] === "" || values[key] === null || key === "productId") {
                            delete values[key]
                        }
                    });

                    return {
                        variables: {
                            parentId: parentComponentId,
                            componentCreateInput: {
                                componentTypeIndex: componentTypeIndex,
                                productId: productIdFromValues,
                                attributes: values,
                                product: productObject ? productObject : undefined,
                            }
                        }
                    }
                }}
                submitTitle={t('Add subcomponent')}
                attributes={attributes}
                beforeSubmit={<Row className={'pr-10'} style={{
                    cursor: 'pointer',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <Row onClick={() => {
                        this.setState({addSubcomponent: !addSubcomponent})
                    }}>
                        <Checkbox checked={addSubcomponent}/>
                        <p className={'ph-10'} style={{margin: '0'}}>{t('Add another subcomponent')}</p>
                    </Row>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                </Row>}
            />
        </Col>;
    }

}

export default compose(
    withRouter,
    withTranslation(),
    graphql(TypeDefinitionAttributeListQuery, {
        name: 'attributeQuery',
        options: props => ({
            variables: {
                index: 'component',
                options: {
                    parentId: props.parentComponentId,
                    componentTypeIndex: props.componentTypeIndex,
                }
            }

        }),
    }),
    waitWhileLoading('attributeQuery'),

    graphql(createComponent, {
        name: 'createComponent',
    }),

    withProfile(),
    withApollo,
)(ComponentCreateSubcomponentForm);
