// @flow
import React, {Component} from "react"

import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import ProductSearch from "./ProductSearch";

type Props = {
    onSelect: ?Function,
    onExtraLink: ?Function,
}

class ProductSearchBar extends Component<Props> {

    state = {
        query: null,
        componentTypeIndex: null
    };

    handleSearch = (val) => {
        this.setState({
            query: val
        });
    };

    handleFilter = (val) => {
        this.setState({
            componentTypeIndex: val
        });
    };

    render() {

        const {onSelect, onExtraLink} = this.props;
        const {query, componentTypeIndex} = this.state;

        return <ProductSearch onSelect={onSelect} componentTypeIndex={componentTypeIndex}
                              handleFilter={this.handleFilter} query={query} debounce={500}
                              handleSearch={this.handleSearch} onExtraLink={onExtraLink}/>
    }

}

export default compose(
    withTranslation()
)(ProductSearchBar);
