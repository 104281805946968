// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import componentQuery from "./../../graphql/queries/component-ComponentDashboard.graphql";
import {Col, Row} from "antd";
import ComponentWidgets from "../ComponentWidgets";
import ComponentHistoryPanel from "../ComponentHistoryPanel/ComponentHistoryPanel";
import ComponentAttributeTabs from "../ComponentAttributeTabs";
import _ from 'lodash';
import type {Query} from "../../types";
import Panel from "../Panel";
import {Link} from "react-router-dom";
import Sizes from "../../themes/default/sizes";
import ComponentActiveServiceTicket from "../ComponentActiveServiceTicket";
import styled from "styled-components";
import ProductInfoWidget from "../ProductInfoWidget";
import withResources, {checkResource} from "../../hoc/withResources";
import FindAssetQuery from "../../graphql-federated/queries/findAsset.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";

const Wrapper = styled.div`
  margin-top: 16px;
`;

const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;


type Props = {
    componentId: number,
    getComponent: Query,
    t: Function,
    activeServiceTicketQuery: Query,
    component?: any,
}

export const semiconComponentType = ['chamber', 'tool']
let attributesBlackList = ["imageId"];

class ComponentDashboard extends Component<Props> {

    render() {

        const {componentId, getComponent, t , resources, findAssetQuery} = this.props;

        const isProduct = _.get(getComponent,'component.componentType.product', false);

        const componentTypeIndex = _.get(getComponent,'component.componentType.index');
        const isSemiconComponentType = semiconComponentType.includes(componentTypeIndex)

        const showLink = checkResource(['updateComponentProduct'], resources);
        const assetId = _.get(findAssetQuery, 'findAsset.id');

        if (isSemiconComponentType) {
            attributesBlackList = [
                ...attributesBlackList,
                "productId"
            ]
        }

        console.log(attributesBlackList, 'attributesBlackList')

        return <div className={'p-10'}>
            <Row gutter={2 * Sizes.grid}>
                <Col sm={24} md={12}>
                    <div className="mv-10" tour-id="componentDashboard__attributes">

                        <Panel title={t('Attributes')}>
                            <ComponentAttributeTabs
                                filter={(attribute) => {
                                    return attributesBlackList.indexOf(attribute.index) === -1
                                }}
                                componentId={componentId}/>
                        </Panel>
                    </div>

                    {/*{ isServiceProvider ?
            <div className="mv-10">
              <Panel title={t('Service Provider')} topRight={<Link to={`/infrastructure/${this.props.componentId}/forms/serviceProvider`}>{t('Edit')}</Link>
              }>
                <ComponentAttributesPanel filter={attr => attr.categories.indexOf('serviceProviderData') !== -1} componentId={componentId}/>
              </Panel>
            </div>
            : null}*/}
                </Col>
                <Col sm={24} md={12}>
                    {
                        isProduct
                            ? <div>
                                {componentTypeIndex !== "machine" ? <div className={'mb-30'}>
                                    <Panel title={t('Product Information')} topRight={showLink ? <Link
                                        to={`/infrastructure/${componentId}/productchange`}>{t("Edit")}</Link> : null}>
                                        {isProduct ? <ProductInfoWidget componentId={componentId}/> : null}
                                    </Panel>
                                </div> : null}

                                <div className={componentTypeIndex === "machine" ? 'mt-50' : null}>
                                    <ComponentActiveServiceTicket  componentId={componentId}/>
                                </div>

                                </div>
                            : null
                    }
                    <div className={`${isProduct ? null : 'mt-50'}`}>
                        <ComponentWidgets componentId={componentId} assetId={assetId} isSemiconComponentType={isSemiconComponentType}/>
                    </div>
                    <Panel containerStyle={{padding: 0}} title={t('History')}
                           topRight={<Link to={`/infrastructure/${componentId}/logs`}>{t('Show all entries')}</Link>}>
                        <ComponentHistoryPanel componentId={componentId}/>
                    </Panel>
                </Col>
            </Row>
        </div>;
    }

}

export default compose(
    withTranslation(),
    graphql(componentQuery, {
        name: 'getComponent',
        options: props => ({
            fetchPolicy: 'cache-and-network',
            variables: {
                id: props.componentId
            }
        }),
    }),
    graphql(FindAssetQuery, {
            name: 'findAssetQuery',
            // skip: (props: Props) => !props.component.serialNo || !props.component.product.catalogNo,
            skip: true,
            options: (props: Props) => ({
                variables: {
                    filter: {
                        serialNumber: { equals: props.component.serialNo },
                        productCode: { equals: props.component.product.catalogNo }
                    }
                },
                context: {
                    clientName: 'federatedGraphEndpoint'
                }
            }),
        }
    ),
    waitWhileLoading('findAssetQuery', ['findAsset'], {
        showLoader: false,
        optional: (props: Props) => !props.component.serialNo || !props.component.product.catalogNo
    }),
    withResources(),
)(ComponentDashboard);
