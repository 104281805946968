// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {Route, Switch, withRouter} from "react-router-dom";
import InfrastructureCreateComponentDetails from "./InfrastructureCreateComponentDetails";
import InfrastructureCreateComponentRoute from "./InfrastructureCreateComponentRoute";
import {compose} from "recompose";
import Redirect from "../Redirect";
import ContentHeader from "../../components/Typo/ContentHeader";
import ContentWrapper from "../../components/ContentWrapper";
import NavigationContainer from "../../components/NavigationContainer";
import InfrastructureCreateTokenRoute from "./InfrastructureCreateToken";
import Error404 from "../errorPages/error404";
import InfrastructureCreateComponentSubcomponentRoute from "./InfrastructureCreateComponentSubcomponentRoute";

type Props = {
    t: Function,
    match: {
        params: {
            id: string
        }
    },
}

class InfrastructureCreateComponent extends Component<Props> {


    render() {

        const {t, match} = this.props;

        return <NavigationContainer
            navigationBar={<ContentHeader title={t('Add component')}/>}
            scroll
        >
            <ContentWrapper>
                <Switch>
                    <Route exact path="/infrastructure/create/:id/details"
                           component={InfrastructureCreateComponentDetails}/>
                    <Route exact path="/infrastructure/create/:id/subcomponent"
                           component={InfrastructureCreateComponentSubcomponentRoute}/>
                    <Route exact path={"/infrastructure/create/:id/:index"}
                           component={InfrastructureCreateComponentRoute}/>
                    <Route exact path="/infrastructure/cmt/:token"
                           component={InfrastructureCreateTokenRoute}/>
                    <Redirect from="/infrastructure/create/:id" to="/infrastructure/create/:id/component"/>
                    <Route component={Error404}/>
                </Switch>
            </ContentWrapper>
        </NavigationContainer>;
    }

}

export default compose(
    withRouter,
    withTranslation()
)(InfrastructureCreateComponent);
