// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "redux";
import _ from 'lodash'
import {Col, message, Popconfirm, Row} from "antd";
import {Button, Icon} from "@dreebit/react-components";
import {withTranslation} from "react-i18next";
import {withProps} from "recompose";
import ContentHeader from "../Typo/ContentHeader";
import processDetails from "./../../graphql/queries/processDetails.graphql";
import deleteProcess from "./../../graphql/mutations/deleteProcess.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {ProcessForm} from "../ProcessForm";
import Panel from "../Panel";
import ContentWrapper from "../ContentWrapper";
import Sizes from "../../themes/default/sizes";
import processList from "../../graphql/queries/processList.graphql";
import {withRouter} from "react-router-dom";
import withProfile from "../../hoc/withProfile";

type Props = {
    id: string,
    processDetailsQuery: any,
    deleteProcessMutation: Function,
    history: {
        push: Function
    }
}

class ProcessDashboard extends Component<Props> {

    handleDelete = () => {
        const {deleteProcessMutation, id, history} = this.props;

        deleteProcessMutation({variables: {id: id}}).then((res) => {
            message.success(res.data.deleteProcess.message);
            history.push(`/admin/processes`)
        });
    };


    render() {

        const {process, id, t} = this.props;
        const deleteButton = <Popconfirm
            placement="bottomRight" title={t("Are you sure?")}
            onConfirm={this.handleDelete}
            okText={t("Yes")}
            cancelText={t("No")}
            icon={<Icon type="question-circle-o" style={{color: 'red'}}/>}
        >
            <Button type="danger" icon="delete">{t('Delete')}</Button>
        </Popconfirm>;

        return <div>
            <ContentHeader title={_.get(process, 'name')} tools={deleteButton} />
            <ContentWrapper>
                <Row gutter={Sizes.grid * 2}>
                    <Col xl={24}>
                        <Panel
                            title={t('Details')}
                        >
                            <ProcessForm id={id}/>
                        </Panel>

                    </Col>
                </Row>
            </ContentWrapper>
        </div>;
    }

}

export default compose(
    withTranslation(),
    withProfile(),
    withRouter,
    graphql(processDetails, {
        name: 'processDetailsQuery',
        options: props => ({
            variables: {
                id: props.id
            }
        }),
    }),
    waitWhileLoading('processDetailsQuery', 'process'),
    graphql(deleteProcess, {
        name: 'deleteProcessMutation',
        options: props => ({
            refetchQueries: [{
                query: processList,
                variables: {
                    params: {
                        start: 0,
                        limit: 40,
                    }
                }
            }]
        })
    }),
    withProps((props) => ({
        process: _.get(props, 'processDetailsQuery.process'),
    }))
)(ProcessDashboard);
